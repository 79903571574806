<template>
	<div id="aliyunOss" class="el-content">
		<a-form :label-col="{span:2}" :wrapper-col="{span:8}">
			<a-form-item label="选择云储存">
				<a-radio-group v-model:value="form.cloud_type">
					<a-radio value="0">本地存储</a-radio>
					<a-radio value="1">阿里云OSS</a-radio>
					<a-radio value="2">七牛云</a-radio>
					<a-radio value="3">腾讯云</a-radio>
				</a-radio-group>
			</a-form-item>
			<!-- 阿里云OSS -->
			<div v-if="form.cloud_type == 1">
				<a-form-item label="Access Key ID">
					<a-input v-model:value="form.cloud_aliyun_accesskey"></a-input>
					<span class="f12">Access Key ID是您访问阿里云API的密钥</span>
				</a-form-item>
				<a-form-item label="Access Key Secret">
					<a-input v-model:value="form.cloud_aliyun_secret"></a-input>
					<span class="f12">Access Key Secret是您访问阿里云API的密钥 (填写完Access Key ID 和 Access Key Secret 后请选择bucket)</span>
				</a-form-item>

				<a-form-item label="bucket选择">
					<a-select v-model:value="bucket_index" placeholder="请选择bucket" @focus="getBucket" @change="checkBucket">
						<a-select-option v-for="(item, index) in bucket" :key="index" :value="index">{{item.show_name}}</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item label="Endpoint">
					<a-input :readonly="true" v-model:value="form.cloud_aliyun_endpoint"></a-input>
					<div class="f12">Endpoint以杭州为例，其它Region请按实际情况填写。http://oss-cn-hangzhou.aliyuncs.com</div>
				</a-form-item>

				<a-form-item label="自定义URL">
					<a-input v-model:value="form.cloud_aliyun_url"></a-input>
					<span class="f12">
						阿里云oss支持用户自定义访问域名，如果自定义了URL则用自定义的URL，如果未自定义，则用系统生成出来的URL。注：自定义url开头加http://或https://结尾不加
						‘/’例：http://aaa.com
					</span>
				</a-form-item>
			</div>

			<!-- 七牛云 -->
			<div v-if="form.cloud_type == 2">
				<a-form-item label="七牛云平台选择">
					<a-radio-group v-model:value="form.cloud_qiniu_type">
						<a-radio label="0" value="0">自定义</a-radio>
						<a-radio label="1" value="1">坤典云储存</a-radio>
					</a-radio-group>
				</a-form-item>
				<div v-if="form.cloud_qiniu_type == 1">
					<div v-if="info && info.is_auth">
						<a-form-item label="Accesskey"><kd-hide-sensitivity-input :value="kdQiniu.key" :is-update="false"></kd-hide-sensitivity-input></a-form-item>
						<a-form-item label="Secretkey"><kd-hide-sensitivity-input :value="kdQiniu.secret" :is-update="false"></kd-hide-sensitivity-input></a-form-item>
						<a-form-item label="bucket选择">
							<a-select v-model:value="form.cloud_qiniu_bucket" placeholder="请选择bucket">
								<a-select-option v-for="(item, index) in qiniuBucket" :key="index" :value="item.bucket">
									{{item.bucket}}
								</a-select-option>
							</a-select>
							<a-button type="primary" @click="show.addbucket = true"><i class="ri-add-fill"></i>添加bucket</a-button>
						</a-form-item>
					</div>
					<div v-else>
						<a-form-item label="云端未授权"><span>云端未授权，暂不能使用坤典云储存</span></a-form-item>
					</div>
				</div>
				<div v-else>
					<a-form-item label="Accesskey">
						<kd-hide-sensitivity-input :value="form.cloud_qiniu_accesskey" @getValue=" e => { form.cloud_qiniu_accesskey = e; } " ></kd-hide-sensitivity-input>
						<span class="f12">用于签名的公钥</span>
					</a-form-item>
					<a-form-item label="Secretkey">
						<kd-hide-sensitivity-input :value="form.cloud_qiniu_secret" @getValue=" e => { form.cloud_qiniu_secret = e; } " ></kd-hide-sensitivity-input>
						<span class="f12">用于签名的私钥</span>
					</a-form-item>
					<a-form-item label="Bucket">
						<a-input v-model:value="form.cloud_qiniu_bucket"></a-input>
						<span class="f12">请确保bucket为可公共读取的</span>
					</a-form-item>
					<a-form-item label="URL">
						<a-input v-model:value="form.cloud_qiniu_url"></a-input>
						<span class="f12">七牛支持用户自定义访问域名。注：url开头加http://或https://结尾不加 ‘/’例：http://aaa.com</span>
					</a-form-item>
				</div>
			</div>

			<!-- 腾讯云 -->
			<div v-if="form.cloud_type == 3">
				<a-form-item label="APPID">
					<a-input v-model:value="form.cloud_tencent_appid"></a-input>
					<span class="f12">APPID项目的唯一ID</span>
				</a-form-item>
				<a-form-item label="SecretID">
					<a-input v-model:value="form.cloud_tencent_secretid"></a-input>
					<span class="f12">SecretID 项目的是安全密钥</span>
				</a-form-item>
				<a-form-item label="SecretKey">
					<a-input v-model:value="form.cloud_tencent_secretkey"></a-input>
					<span class="f12">SecretKEY 项目的是安全密钥</span>
				</a-form-item>
				<a-form-item label="Bucket">
					<a-input v-model:value="form.cloud_tencent_bucket"></a-input>
					<span class="f12">请确保bucket为可公共读取的</span>
				</a-form-item>
				<a-form-item label="自定义URL">
					<a-input v-model:value="form.cloud_tencent_url"></a-input>
					<span class="f12">腾讯云支持用户自定义访问域名。注：url开头加http://或https://结尾不加 ‘/’例：http://aaa.com</span>
				</a-form-item>
				<a-form-item label="地区跳线"><a-input v-model:value="form.cloud_tencent_address"></a-input></a-form-item>
			</div>

			<a-form-item :wrapper-col="{offset:2}">
				<a-button type="primary" @click="submitSave">保存</a-button>
			</a-form-item>
		</a-form>
		
		<a-modal v-model:visible="show.addbucket" title="添加bucket" @ok="qiniuBucketSave" @cancel="show.addbucket = false" width="600px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="空间名称"><a-input v-model:value="bucketForm.bucket" placeholder="空间名称，要求在七牛云对象存储范围内唯一"></a-input></a-form-item>
				<a-form-item label="区域">
					<a-select v-model:value="bucketForm.zone">
						<a-select-option value="">选择区域</a-select-option>
						<a-select-option value="z0">z0</a-select-option>
						<a-select-option value="z1">z1</a-select-option>
						<a-select-option value="z2">z2</a-select-option>
						<a-select-option value="na0">na0</a-select-option>
						<a-select-option value="as0">as0</a-select-option>
					</a-select>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import saasSetModel from '@/api/saas/set';
import tool from '@/util/tool';
export default {
	setup(){
		const state = reactive({
			info: null,
			show: {
				addbucket: false
			},
			form: {
				cloud_type: '1',
				cloud_aliyun_accesskey: '',
				cloud_aliyun_secret: '',
				cloud_aliyun_bucket: '',
				cloud_aliyun_endpoint: '',
				cloud_aliyun_url: '',

				cloud_tencent_appid: '',
				cloud_tencent_secretid: '',
				cloud_tencent_secretkey: '',
				cloud_tencent_bucket: '',
				cloud_tencent_url: '',
				cloud_tencent_address: '',

				cloud_qiniu_accesskey: '',
				cloud_qiniu_secret: '',
				cloud_qiniu_bucket: '',
				cloud_qiniu_url: '',
				cloud_qiniu_type: '0'
			},
			bucket: [],
			bucket_index: '',
			qiniuBucket: [], //七牛云bucket
			kdQiniu: {
				//坤典七牛云信息
				key: '',
				secret: ''
			},

			bucketForm: {
				//添加坤典云空间信息
				bucket: '',
				zone: ''
			}
		})

		saasSetModel.getWebConfigSite(null,res=>{
			state.info = res
			state.form = tool.filterForm(Object.keys(state.form),res.cloud_json)
			if(state.form.cloud_aliyun_accesskey && state.form.cloud_aliyun_secret){
				getBucket()
			}
			if( res.is_auth ){
				saasSetModel.getQiniuKeyAndSc(res=>state.kdQiniu = res)
				qiniuBucketGet()
			}
		})

		function submitSave(){
			let form = JSON.parse(JSON.stringify(state.form))
			form.scene = 'cloud_store';

			if (form.cloud_qiniu_type == 1) {
				form.cloud_qiniu_accesskey = state.kdQiniu.key;
				form.cloud_qiniu_secret = state.kdQiniu.secret;
				if (form.cloud_qiniu_bucket && state.qiniuBucket ) {
					for (var i = 0; i < state.qiniuBucket.length; i++) {
						if (state.qiniuBucket[i].bucket == form.cloud_qiniu_bucket) {
							form.cloud_qiniu_url = 'http://' + state.qiniuBucket[i].domain;
							break;
						}
					}
				}
			}
			saasSetModel.saveWebConfigSite(form)
		}

		function getBucket(){
			let { form } = state
			let { cloud_aliyun_accesskey, cloud_aliyun_secret, cloud_aliyun_bucket } = form;
			if (cloud_aliyun_accesskey == '') {
				tool.message("请填写Access Key ID","warning");
				return;
			}

			if (cloud_aliyun_secret == '') {
				tool.message("请填写Access Key Secret","warning");
				return;
			}

			saasSetModel.getAliBuket(cloud_aliyun_accesskey,cloud_aliyun_secret,res=>{
				state.bucket = res
				if (cloud_aliyun_bucket != '') {
					Object.keys(state.bucket).forEach(function(key) {
						if (state.bucket[key].bucket == cloud_aliyun_bucket) {
							state.bucket_index = parseInt(key);
						}
					});
				}
			})
		}

		function checkBucket(){
			let bucket = state.bucket[state.bucket_index];
			state.form.cloud_aliyun_bucket = bucket.bucket;
			state.form.cloud_aliyun_endpoint = bucket.endpoint;
		}

		function qiniuBucketGet(){
			saasSetModel.getQiniuBuketList(1,999,{pid:state.info.program_id},res=>{
				console.log('qiniuBucket',res.list);
				
				state.qiniuBucket = res.list
			})
		}

		function qiniuBucketSave(){
			let form = JSON.parse(JSON.stringify(state.bucketForm))
			if( state.info && state.info.program_id ) form.pid = state.info.program_id
			saasSetModel.createQiniuBuket(form,()=>{
				state.show.addbucket = false
				state.qiniuBucketGet()
				state.bucketForm = {
					bucket: '',
					zone: ''
				}
			})
		}

		return{
			...toRefs(state),
			submitSave,
			checkBucket,
			qiniuBucketGet,
			qiniuBucketSave,
			getBucket
		}
	}
}
</script>

<style>
.lng-lat {
	display: flex;
}
</style>
